import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTransition from 'gatsby-plugin-page-transitions';
import { navigate } from 'gatsby-link'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa"

import { responsiveTitle1 } from '../components/typography.module.css'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
      _rawBody
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }


  return (
    <Layout>
      <PageTransition>
      <SEO title={page.title} />
      <Container>
      <div className="inner-container">
        <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>{page.title}</h1>
        <BlockContent blocks={page._rawBody || []} />

        <div className="flex">
          <div className="inner">
            <div className="social-links">
              <OutboundLink target="_blank" href="https://www.facebook.com/SadieRockYoga"><FaFacebookF />Facebook</OutboundLink>
              <OutboundLink target="_blank" href="https://www.instagram.com/sadierockyoga/"><FaInstagram />Instagram</OutboundLink>
              <OutboundLink target="_blank" href="https://www.youtube.com/channel/UCS1d1EkhBhAcUsAoVq4IhHg"><FaYoutube />YouTube</OutboundLink>
            </div>
          </div>

          <div className="inner">
          <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Name:
            <br />
            <input type="text" name="name" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Email:
            <br />
            <input required type="email" name="email" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Message:
            <br />
            <textarea required name="message" onChange={handleChange} />
          </label>
        </p>
        <p>
          <button type="submit" className="btn-link">Send</button>
        </p>
      </form>
          </div>
        </div>
      </div>
      </Container>
      </PageTransition>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage
